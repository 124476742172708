<template>
  <DefaultTemplate :side="false">
    <el-row type="flex" justify="center" class="mg-t-7 h-100 w-100">
      <el-col :sm="20" :md="16" :lg="10">
        <el-card class="box-card">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-position="top"
          >
            <el-form-item label="E-mail" prop="email">
              <el-input
                v-model="ruleForm.email"
                placeholder="name@example.com"
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
            </el-form-item>
            <el-form-item label="รหัสผ่าน" prop="password">
              <el-input
                type="password"
                show-password
                v-model="ruleForm.password"
                @keyup.enter.native="submitForm('ruleForm')"
              ></el-input>
            </el-form-item>
            <el-form-item class="text-center">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >เข้าสู่ระบบ</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </DefaultTemplate>
</template>
<script>
import "@/mixin/DefaultTemplate";
import api from "@/service/Auth";
import { HTTP } from "@/service/axios";
export default {
  data() {
    return {
      url: process.env.VUE_APP_BASE_URL,
      ruleForm: {
        email: "",
        password: ""
      },
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่าน",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let obj = {
            email: this.ruleForm.email,
            password: this.ruleForm.password
          };
          let res = await api.signin(obj);
          if (res.data.success) {
            this.$store.commit("SET_USER", res.data.obj);
            this.$store.commit("SET_TOKEN", res.data.obj.token);
            this.$router.push("/");
          } else {
            this.$message.error("รหัสผ่านหรืออีเมลไม่ถูกต้อง");
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>