// @ts-nocheck
import { HTTP } from "@/service/axios";
import alert from "@/service/Alert";
export default {
  signin: async (obj) => {
    return HTTP.post(`sign/in`, obj).catch((e) => {
      console.log(`signin ERROR ${e}`);
      if (e == "Error: Request failed with status code 401") {
        alert.CheckAuth();
      } else {
        alert.alertCatchError(e);
      }
    });
  },
};
